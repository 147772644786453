import React,{  useState } from "react";
import { Link } from "@StarberryUtils"
import { Container, Col} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/PlayVideo";
import  './MemberDetails.scss';
import ContactForm from "@Components/forms/general-enquiry"
import Modal from 'react-bootstrap/Modal'
import { InlineWidget } from "react-calendly";
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { uknumberformatFront } from "@Components/common/common-functions"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
    const MemberDetails = ( props ) => {
    const [isPlaying,setPlaying] = useState(false);
    const [formOpen, setOpen] = useState(false)
    const [videoId, setVideoId] = useState('');
    const [show, setShow] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    // const setPlay = (id) => {
    //   var videoid = getVideoId(id)    
    //   setPlaying(true)
    //   setVideoId(videoid.id)
    //   console.log(videoid)
  
    // }
    const playVideo = (video_id) => {  
      // setVideoindex(video_index)   
      setShowVideo(true)
  }
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }

    if (props.data?.Embed_Video_URL) {
      var videoid = getVideoId(props.data?.Embed_Video_URL);
     }
     if (props.data?.imagetransforms?.Image_Transforms) {
      var processedImages = props.data.imagetransforms.Image_Transforms;
  }
  return(

      <div className="member-details">
         <Container>
             <div className="member-details-row">
                 <div className="row">
                     <Col xs="12" className="position-static">
                       {/* {props.data?.Embed_Video_URL} */}
                       {showVideo && <div onClick={(e) => {setShowVideo(false)}} className="close-iframe-btn white"></div> }
                        <div className="member-details-img img-zoom">
                        
                        <picture>
                           
                            {/* <source media="(min-width:992px)" srcset={props.data?.Image?.url} /> */}
                            {/* <img className="" src={props.data?.Image?.url} alt={props.data?.Image?.alternativeText} /> */}
                            <ImageTransform imagesources={props.data?.Image?.url} renderer="srcSet" imagename='team.Image.detail'
                                     attr={{ alt: props.data?.Image?.alternativeText, className: ''}}
                                     imagetransformresult={processedImages} id={props.data?.id} />
                            
                            {showVideo &&
                        <YouTube
                        video={videoid.id}
                        autoplay
                        onEnd={e => {setShowVideo(false)}}
                        modestBranding={1}
                        onPlaying={trackerVideo(props.data?.Name)}
                        showRelatedVideos={false}
                        showInfo={false}
                        annotations={false}
                        
                        />
                        }
                         </picture>
                         {showVideo ? null :
                         props.data?.Embed_Video_URL ? <strong href="" className={"play-btn"} 
                         onClick = { (e) => {playVideo(videoid.id)}}
                        //  onClick = { e => {setPlay(props.data?.Embed_Video_URL)}}
                         ><i className="icon-play"></i></strong> : null}
                        </div>
                     </Col>
                     {formOpen ? <div onClick={() => setOpen(false)} onKeyPress={() => setOpen(false)} className="details-page-back">&lt; Back to {props?.data?.Name}</div> : null}
                     <Col xs="12" className="text-center text-lg-left">
                     
                     {formOpen ?  <div className="member_info"><ContactForm person_contact={true} to_email_id={props.data?.Email} /></div> : <>
                     <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                        <div className="member_info">
                            <h1>{props.data?.Name}</h1>
                            <span>{props.data?.Designation}</span>
                        </div>
                        <button 
                        onClick={() => setOpen(true)}
                         className="btn btn-primary dark-bg">Email {props.data?.Name}</button>
                        <div className="member_contact_col">
                            {props.data?.Phone ? <Link href={'tel:'+uknumberformatFront(props.data?.Phone)}><i className="icon-phoneicon"></i> {uknumberformatFront(props.data?.Phone)}</Link> : null}
                            {props.data?.Book_A_Video ? <a onClick={() => setShow(true)} className="book-a-call"><i className="icon-chat"></i> Book a Video Call</a> : null}
                        </div>
                        </ScrollAnimation>
                        </>}
                     </Col>
                 </div>
             </div>
             <PlayVideo
              isOpen = { isPlaying } 
              isCloseFunction = { setPlaying }
              videoId={videoId}
              isAutoPlay={1}
            />
         </Container>
         <Modal
        show={show}
        centered={true}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w modal-form"
        aria-labelledby="example-custom-modal-styling-title"
      >
                  <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Book a Video Call
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <InlineWidget url={props.data?.Book_A_Video} />
        </Modal.Body>
      </Modal>
      </div>
         
  
)
  }



export default MemberDetails