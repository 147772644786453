import React from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/layout";
import SEO from "@Components/seo";
import { Container } from 'react-bootstrap';
import StaticContactBlock from '@Components/Static/StaticContactBlock/StaticContactBlock'
import MemberDetails from '@Components/Static/MemberDetails/MemberDetails'
import ContentBlockText from '@Components/Static/ContentBlockText/ContentBlockText'
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import BreadcrumbsBottom from "@Components/Static/BreadcrumbsBottom/BreadcrumbsHome"
const OurTeam = (props) => {
    const GET_TEAM_MEMBER = gql`
  query GetTeam($URL: String!){	
    teams(where:{URL: $URL}){
        id
        imagetransforms
      Name
      Phone
      About
      URL
      Email
      Image {
        url
      }
      Embed_Video_URL
      Book_A_Video
      Designation
      Meta_Title
      Meta_Description
    }
  }
`;

    const { loading, data } = useQuery(GET_TEAM_MEMBER, {
        variables: { URL: props.slug }
    });

    // console.log(data)

    if (loading) return (
        <div className="static-news gql-loading-screen">
            <Container>
                <div className="gql-loading"><div className="dot-pulse"></div></div>
            </Container>
        </div>);
    return (
        <Layout css={true}>
            {data?.teams.map((Page, i) => {
                return (
                    <>
                        <SEO title={Page ? Page.Name+ ' | '+  Page.Designation : ''} description={'Know about '+Page.Name+', '+Page.Designation+' here. We are here to help you with the property solutions.'} />
                        <Helmet
                            bodyAttributes={{
                                class: `templates-team-details-template homepage`
                            }}
                        />
                        <MemberDetails data={Page} />
                        <ContentBlockText isTeam={false} data={Page?.About} />
                        <StaticContactBlock />
                        <BreadcrumbsBottom Page={Page.Name} class="static" alias={'our-team'} type="details-page" />
                    </>
                )
            })}
        </Layout>
    );
}



export default OurTeam

